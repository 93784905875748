<div class="qoption">
  <mat-card class="qoption-op">
    <app-grow-input
      [control]="option.nameControl"
      (inputEmitter)="optionNameInput($event)"
      (blurEmitter)="optionNameBlur()"
      [useNativeInput]="true"
      minWidth="100%"
      maxWidth="100%"
      class="qoption-op-name"
    ></app-grow-input>
    <mat-form-field appearance="outline" class="qoption-op-desc">
      <mat-label>Description</mat-label>
      <textarea
        [formControl]="option.descControl"
        matInput
        class="qoption-op-desc-in"
      ></textarea>
      <div class="qoption-op-desc-char">
          <span class="qoption-op-desc-char-len" [style]="{color: option.descControl.value.length <= 1024?'var(--gpc-primary)' : 'var(--gpc-warn)'}">{{option.descControl.value.length}}{{' '}}</span>
          /1024
      </div>
    </mat-form-field>
    <div class="qoption-op-actions">
      <div class="qoption-op-actions-back">
        <button (click)="destroyQuoteOption()" color="warn" mat-flat-button>Delete</button>
      </div>
      <div class="qoption-op-actions-back">
        <button [disabled]="isCancelOptionDisabled()" color="warn" mat-flat-button>Cancel</button>
        <button (click)="saveQuoteOptionChanges()" [disabled]="isSaveOptionDisabled()" color="primary" mat-flat-button>Save</button>
      </div>
    </div>
  </mat-card>

  <div class="qoption-overview">
    <div class="qoption-overview-credit qoption-overview-item">
      <div class="qoption-overview-credit-title">
        <h3>Credited Parts</h3>
      </div>
      <div class="qoption-overview-credit-list">
        <h4>Total Items: {{ qe.getCreditedQuoteOptionKitPartsTotalParts({quoteOption: option}) }}</h4>
        <h4>Total Cost: <span class="dollar">$</span> {{ qe.getCreditedQuoteOptionKitPartsCost({quoteOption: option}).toFixed(2) }}</h4>
        <h4>Raw Cost: <span class="dollar">$</span> {{ qe.getCreditedQuoteOptionKitPartsRawCost({quoteOption: option}).toFixed(2) }}</h4>
        <hr />
        <h4>Mat Items: {{ qe.getCreditedQuoteOptionKitPartsMaterialTotalItems({quoteOption: option}) }}</h4>
        <h4>Mat Cost: <span class="dollar">$</span> {{ qe.getCreditedQuoteOptionKitPartsMaterialCost({quoteOption: option}).toFixed(2) }}</h4>
        <h4>Raw Mat Cost: <span class="dollar">$</span> {{ qe.getCreditedQuoteOptionKitPartsRawMaterialCost({quoteOption: option}).toFixed(2) }}</h4>
        <hr />
        <h4>Labor Items: {{ qe.getCreditedQuoteOptionKitPartsLaborTotalItems({quoteOption: option}) }}</h4>
        <h4>Labor Cost: <span class="dollar">$</span> {{ qe.getCreditedQuoteOptionKitPartsLaborTotalCost({quoteOption: option}).toFixed(2) }}</h4>
        <h4>Raw Labor Cost: <span class="dollar">$</span> {{ qe.getCreditedQuoteOptionKitPartsRawLaborCost({quoteOption: option}).toFixed(2) }}</h4>
      </div>
    </div>
    <div class="qoption-overview-replace qoption-overview-item">
      <div class="qoption-overview-credit-title">
        <h3>Replacement Parts</h3>
      </div>
      <div class="qoption-overview-credit-list">
        <h4>Total Items: {{ qe.getReplacementQuoteOptionKitPartsTotalParts({quoteOption: option}) }}</h4>
        <h4>Total Cost: <span class="dollar">$</span> {{ qe.getReplacementQuoteOptionKitPartsCost({quoteOption: option}).toFixed(2) }}</h4>
        <h4>Raw Cost: <span class="dollar">$</span> {{ qe.getReplacementQuoteOptionKitPartsRawCost({quoteOption: option}).toFixed(2) }}</h4>
        <hr />
        <h4>Mat Items: {{ qe.getReplacementQuoteOptionKitPartsMaterialTotalItems({quoteOption: option}) }}</h4>
        <h4>Mat Cost: <span class="dollar">$</span> {{ qe.getReplacementQuoteOptionKitPartsMaterialCost({quoteOption: option}).toFixed(2) }}</h4>
        <h4>Raw Mat Cost: <span class="dollar">$</span> {{ qe.getReplacementQuoteOptionKitPartsRawMaterialCost({quoteOption: option}).toFixed(2) }}</h4>
        <hr />
        <h4>Labor Items: {{ qe.getReplacementQuoteOptionKitPartsLaborTotalItems({quoteOption: option}) }}</h4>
        <h4>Labor Cost: <span class="dollar">$</span> {{ qe.getReplacementQuoteOptionKitPartsLaborCost({quoteOption: option}).toFixed(2) }}</h4>
        <h4>Raw Labor Cost: <span class="dollar">$</span> {{ qe.getReplacementQuoteOptionKitPartsRawLaborCost({quoteOption: option}).toFixed(2) }}</h4>
      </div>
    </div>
  </div>

  <div class="qoption-tabs">
    <mat-tab-group mat-align-tabs="center" class="tab-custom-overflow">
      <mat-tab label="Credited Parts">
        <div class="qoption-tabs-credited">
          <ng-container
            *ngIf="qe.quote != null"
          >
            <ng-container *ngFor="let kit of qe.getKits(); let skIndex = index; trackBy : trackByField('QuoteKitPart_guid')">
              <app-qopcredrow
                [qkp]="kit"
                [qop]="option"
              ></app-qopcredrow>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="qe.quote != null"
          >
            <ng-container *ngFor="let part of qe.getParts(); let pIndex = index; trackBy : trackByField('QuoteKitPart_guid'); let last = last">
              <app-qopcredrow
                [qkp]="part"
                [qop]="option"
              ></app-qopcredrow>
            </ng-container>
          </ng-container>
          <div *ngIf="showCreditedActions" class="qoption-tabs-credited-actions">
            <button (click)="resetSelectedCredited()" color="warn" mat-flat-button>Cancel</button>
            <button (click)="saveChangesCredited()" color="primary" mat-flat-button>Save</button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Replacement Parts">
        <ng-template matTabContent>
          <div class="qoption-tabs-parts">
            <div class="qoption-tabs-add">
              <button 
                long-press 
                (onLongPress)="menuTrigger.openMenu()" 
                (onLongPressMiss)="addSheetOpen=true"
                mat-fab 
                class="qoption-tabs-add-btn"
              >
                <i class="fas fa-plus"></i>
                <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" [style]="{width: '0px', height: '0px'}"></button>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="addSheetOpen=true" mat-menu-item>Add parts / kits</button>
                <button (click)="openCustomKitSheet()" mat-menu-item>Add a custom kit</button>
                <button (click)="openCustomPartSheet()" mat-menu-item>Add a custom part</button>
              </mat-menu>
            </div>
            <div class="qoption-tabs-parts-list">
              <ng-container
                *ngFor="let qokp of this.qe.getOptionKits({quoteOption: option}); let kIndex = index; trackBy : trackByField('QuoteOptionKitPart_guid')"
              >
                <app-qoptionkprow
                  [qokp]="qokp"
                  [qop]="option"
                  (openAddSheet)="openAddBotSheet($event)"
                  (customKitSheet)="openCustomKitSheet($event)"
                  (customPartSheet)="openCustomPartSheet($event)"
                >
                </app-qoptionkprow>
              </ng-container>
              <ng-container
                *ngFor="let qokp of this.qe.getOptionParts({quoteOption: option}); let pIndex = index; trackBy : trackByField('QuoteOptionKitPart_guid')"
              >
                <app-qoptionkprow
                  [qokp]="qokp"
                  [qop]="option"
                >
                </app-qoptionkprow>
              </ng-container>
            </div>
            <div *ngIf="qe.getSelectedQuoteOptionKitPartChildren({quoteOption: option}).length > 0" class="qoption-tabs-credited-actions">
              <button 
                (click)="destroyQuoteOptionKitParts()" 
                color="warn" 
                mat-flat-button
              >
                Delete
              </button>
              <button 
                [disabled]="qe.getSelectedQuoteOptionKitPartChildren({quoteOption: option}).length > 1" 
                (click)="openEditSheet()" 
                color="primary" 
                mat-flat-button
              >
                Edit
              </button>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<app-korp-picker-botsheet
  [open]="addSheetOpen"
  [parent]="addBotSheetTarget"
  [existingQuoteParts]="addSheetOpen?qe.getQuoteParts():[]"
  (sheetClosed)="closeAddPartsSheet()"
  (addItems)="
    addToQuoteOption($event);
    closeAddPartsSheet();
  "
  class="qoption-add"
>
</app-korp-picker-botsheet>

<app-customqkit-botsheet
  [open]="customKitSheetOpen"
  [parentQuoteOrKit]="customKitTarget?customKitTarget:qe.quote"
  (sheetClosed)="closeCustomKitSheet()"
  (addQuoteKit)="addCustomKitToQuoteOption($event)"
  class="qoption-add"
>
</app-customqkit-botsheet>

<app-customqpart-botsheet
  [open]="customPartSheetOpen"
  [parentQuoteOrKit]="customPartTarget?customPartTarget:qe.quote"
  (sheetClosed)="closeCustomPartSheet()"
  (addQuotePart)="addCustomPartToQuoteOption($event)"
  class="qoption-add"
>
</app-customqpart-botsheet>

<app-editqopkpbs
  *ngIf="qe.getSelectedQuoteOptionKitPartChildren({quoteOption: option}).length == 1"
  [qopkp]="qe.getSelectedQuoteOptionKitPartChildren({quoteOption: option})[0]"
  [open]="editPartBotSheetOpen"
  (partSaved)="editPartBotSheetOpen=false; cdr.detectChanges()"
  (sheetClosed)="editPartBotSheetOpen=false"
>
</app-editqopkpbs>