import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import { MatTableDataSource } from "@angular/material/table";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import DateValidators from "src/app/Validators/DateValidators";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import ColNameAndTitleInterface from "./interfaces/ColNameAndTitleInterface";
import ColNameTitleInterfaceAndRangeInterface from "./interfaces/ColNameTitleInterfaceAndRangeInterface";
import TableColumnsDataInterface from "./interfaces/TableColumnsDataInterface";
import { ScreenSizeService } from "src/app/_services/ScreenSizeService/ScreenSizeService";
import { SageApiService } from "src/app/_services/sageApi/sageApi.service";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.css"],
})
export class DataTableComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @Input() title: string = "";
  @Input() rawData: T[];
  @Input() tableColumns: TableColumnsDataInterface<T>[];
  @Input() isLoading: boolean = false;

  @Input() selColumns: ColNameAndTitleInterface<T>[] = [];
  selControls: FormControl[] = [];
  selColumnOptions: string[][] = [[]];

  @Input() mselColumns: ColNameAndTitleInterface<T>[] = [];
  mselControls: FormControl[] = [];
  mselColumnOptions: string[][] = [[]];

  @Input() inColumns: ColNameAndTitleInterface<T>[] = [];
  inControls: FormControl[] = [];

  @Input() dpickerColumns: ColNameTitleInterfaceAndRangeInterface<T>[] = [];
  dpickerControls: FormGroup[] = [];

  @Input() drangeColumns: ColNameTitleInterfaceAndRangeInterface<T>[] = [];
  drangeControls: FormGroup[] = [];

  @Input() includeColumnSelector: boolean = false;
  includeColumnSelectorControl: FormControl = new FormControl([]);
  includeColumnSelectorOptions: string[] = [];
  includeColumnSelectorDefaultOptions: string[] = [];

  @Input() includeReset: boolean = false;

  @Input() noControls: boolean = false;

  @Input() accordianControls: boolean = false;
  @Input() accordianScreenSize: string = "xs";
  @Input() accordianTitle: string = "Filters";

  @Input() pageSizeOptions: number[] = [10, 20];

  @Input() searchOnChange: boolean = false;

  // Don't do this if you have a truly large amount of data, it's a huge performance hit on the server and will take forever!
  @Input() includePdfDownload: boolean = false;
  @Input() pdfTitle: string|null = null;

  data: MatTableDataSource<T>;

  hasControlChanged: boolean = false;

  @ViewChild("dataPaginator", { read: MatPaginator })
  dataPaginator: MatPaginator;
  @ViewChild(MatSort) dataSorter: MatSort;

  constructor(
    breakpointObserver: BreakpointObserver,
    public screenSize: ScreenSizeService,
    public api: SageApiService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.resetControlsAndOptions();
  }

  ngOnInit() {
    this.resetControlsAndOptions();
  }
  ngAfterViewInit() {
    this.data.paginator = this.dataPaginator;
    this.data.sort = this.dataSorter;
  }

  resetControlsAndOptions() {
    this.data = new MatTableDataSource<T>(this.rawData);

    // Selects
    const selControls = [];
    const selColumnOptions = [];
    for (let sel of this.selColumns) {
      selControls.push(
        new FormControl(this.getAllFromOneColumn(sel.col)[0] || "", [
          Validators.required,
        ])
      );
      selColumnOptions.push([...this.getAllFromOneColumn(sel.col)]);
    }
    this.selControls = selControls;
    this.selColumnOptions = [...selColumnOptions];

    // Multi selects
    const mselControls = [];
    const mselColumnOptions = [];
    for (let sel of this.mselColumns) {
      mselControls.push(
        new FormControl(
          [...this.getAllFromOneColumn(sel.col)],
          [Validators.required]
        )
      );
      mselColumnOptions.push([...this.getAllFromOneColumn(sel.col)]);
    }
    this.mselControls = mselControls;
    this.mselColumnOptions = [...mselColumnOptions];

    // Inputs
    const inControls = [];
    for (let input of this.inColumns) {
      inControls.push(new FormControl("", []));
    }
    this.inControls = inControls;

    // Date pickers
    const dpickerControls = [];
    for (let dpicker of this.dpickerColumns) {
      dpickerControls.push(
        new FormGroup({
          min: new FormControl(new Date(dpicker.range.min), [
            Validators.required,
          ]),
          max: new FormControl(new Date(dpicker.range.max), [
            Validators.required,
          ]),
          value: new FormControl(new Date(), [
            Validators.required,
            DateValidators.dateBetweenValidator("value", "min", "max", {
              minmax: true,
            }),
          ]),
        })
      );
    }
    this.dpickerControls = dpickerControls;

    // Date Ranges
    const drangeControls = [];
    for (let drange of this.drangeColumns) {
      const min = new Date(drange.range.min);
      const max = new Date(drange.range.max);
      drangeControls.push(
        new FormGroup({
          min: new FormControl(min),
          max: new FormControl(max),
          start: new FormControl(min, [
            Validators.required,
            DateValidators.dateBetweenValidator("start", "min", "end", {
              minmax: true,
            }),
          ]),
          end: new FormControl(max, [
            Validators.required,
            DateValidators.dateBetweenValidator("end", "start", "max", {
              minmax: true,
            }),
          ]),
        })
      );
    }
    this.drangeControls = drangeControls;

    // IncludeColumnSelector
    const incColDefaultSelected = [];
    const incColOptions = [];
    for (let incCol of this.tableColumns) {
      if (incCol.includeColumnByDefault !== false) {
        incColDefaultSelected.push(incCol.title);
      }
      incColOptions.push(incCol.title);
    }
    this.includeColumnSelectorOptions = [...incColOptions];
    this.includeColumnSelectorDefaultOptions = [...incColDefaultSelected];
    this.includeColumnSelectorControl = new FormControl([
      ...incColDefaultSelected,
    ]);

    this.data.paginator = this.dataPaginator;
    this.data.sort = this.dataSorter;

    if (!this.noControls) {
      this.onSearch();
    }
  }

  onSearch() {
    this.hasControlChanged = false;
    const newData = [];
    for (let drow of this.rawData) {
      let drowPassedCheck = true;

      for (
        let mselIndex = 0;
        mselIndex < this.mselControls.length;
        mselIndex++
      ) {
        const msel = this.mselControls[mselIndex];
        const colName = this.mselColumns[mselIndex];

        if (!msel.value.includes(drow[colName.col])) {
          drowPassedCheck = false;
        }
      }
      for (let selIndex = 0; selIndex < this.selControls.length; selIndex++) {
        const sel = this.selControls[selIndex];
        const colName = this.selColumns[selIndex];

        if (sel.value != drow[colName.col]) {
          drowPassedCheck = false;
        }
      }
      for (
        let dpcIndex = 0;
        dpcIndex < this.dpickerControls.length;
        dpcIndex++
      ) {
        const dpc = this.dpickerControls[dpcIndex];
        const colName = this.dpickerColumns[dpcIndex];

        const dpcDate = new Date(dpc.value.value).toISOString();
        const drowColDate = new Date(
          drow[colName.col]?.toString()
        )?.toISOString();

        if (dpcDate.slice(0, 10) != drowColDate.slice(0, 10)) {
          drowPassedCheck = false;
        }
      }
      for (
        let drcIndex = 0;
        drcIndex < this.drangeControls.length;
        drcIndex++
      ) {
        const drc = this.drangeControls[drcIndex];
        const colName = this.drangeColumns[drcIndex];

        const start = new Date(drc.value.start).getTime();
        const end = new Date(drc.value.end).getTime();
        const drowColTime = new Date(drow[colName.col]?.toString())?.getTime();

        if (drowColTime < start || drowColTime > end) {
          drowPassedCheck = false;
        }
      }
      for (let inIndex = 0; inIndex < this.inControls.length; inIndex++) {
        const inp = this.inControls[inIndex];
        const colName = this.inColumns[inIndex];

        if (inp.value != "") {
          if (
            !drow[colName.col]
              ?.toString()
              ?.toLowerCase()
              ?.includes(inp.value.toLowerCase())
          ) {
            drowPassedCheck = false;
          }
        }
      }
      if (drowPassedCheck) {
        newData.push(drow);
      }
    }
    this.data = new MatTableDataSource<T>(newData);
    this.data.paginator = this.dataPaginator;
    this.data.sort = this.dataSorter;
  }

  getTableColumns(): string[] {
    if (!this.tableColumns || this.tableColumns.length == 0) {
      return [];
    }
    const selectedTableColumns = [];
    for (let tc of this.tableColumns) {
      if (this.includeColumnSelectorControl.value.includes(tc.title)) {
        selectedTableColumns.push(tc.col.toString());
      }
    }
    return selectedTableColumns;
  }

  getAllFromOneColumn(colName: keyof T): string[] {
    const allValues = [];
    for (let row of this.rawData) {
      if (
        row[colName] != undefined &&
        allValues.find((av) => av == row[colName]) == undefined
      ) {
        allValues.push(row[colName].toString());
      }
    }
    return allValues;
  }

  getColumnValue(
    element,
    col: keyof T,
    value: string | ((rawVal, col) => string)
  ): string {
    if (typeof value == "string") {
      return element[value];
    }
    return value(element, col);
  }

  getSearchable() {
    for (let msel of this.mselControls) {
      if (!msel.valid) {
        return false;
      }
    }
    for (let sel of this.selControls) {
      if (!sel.valid) {
        return false;
      }
    }
    for (let dpc of this.dpickerControls) {
      if (!dpc.valid) {
        return false;
      }
    }
    for (let drc of this.drangeControls) {
      if (!drc.valid) {
        return false;
      }
    }
    for (let inp of this.inControls) {
      if (!inp.valid) {
        return false;
      }
    }
    if (!this.hasControlChanged) {
      return false;
    }
    return true;
  }

  onAnyControlChange() {
    this.hasControlChanged = true;
    if (this.searchOnChange && this.getSearchable()) {
      this.onSearch();
    }
  }

  onSelChange(event, sel: ColNameAndTitleInterface<T>, index: number) {
    this.selControls[index] = event;
    this.onAnyControlChange();
  }

  onMselChange(event, msel: ColNameAndTitleInterface<T>, index: number) {
    this.mselControls[index] = event;
    this.onAnyControlChange();
  }

  onDrangeChange(
    event,
    drange: ColNameTitleInterfaceAndRangeInterface<T>,
    index: number
  ) {
    // The value is being set in the template
    this.onAnyControlChange();
  }

  onDpickerChange(
    event,
    dpicker: ColNameTitleInterfaceAndRangeInterface<T>,
    index: number
  ) {
    // The value is being set in the template
    this.onAnyControlChange();
  }

  onInputChange(event, inp: ColNameAndTitleInterface<T>, index: number) {
    this.inControls[index].setValue(event.target.value);
    this.onAnyControlChange();
  }

  normalizeDateStringToISO(ds: string) {
    return new Date(ds).toISOString();
  }

  isColumnIncludedInColumnSelect(col: keyof T): boolean {
    if (this.includeColumnSelectorControl.value.includes(col)) {
      return true;
    }
    return false;
  }

  getTableColumnsThatMatchColumnSelect() {
    const newTableColumns = [];
    for (let tc of this.tableColumns) {
      if (this.includeColumnSelectorControl.value.includes(tc.title)) {
        newTableColumns.push({ ...tc, hidden: false });
      } else {
        newTableColumns.push({ ...tc, hidden: true });
      }
    }
    return newTableColumns;
  }

  onSelectedColumnsChange(event) {
    this.includeColumnSelectorControl.setValue(event.value);
  }

  openPdf() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Get the currently shown data in the table

    const groupByDirection: string = this.data.sort.direction;
    
    const groupBy: string = groupByDirection != '' ? this.data.sort.active : undefined;

    let tableHeaders = Object.keys(this.data.filteredData[0]);
    // Filter tableHeaders by this.includeColumnSelectorControl
    tableHeaders = tableHeaders.filter((th) =>
      this.includeColumnSelectorControl.value.includes(th)
    );

    const tableData = this.data.filteredData.map((row) => {
        const newRow = [];
        for (let th of tableHeaders) {
            
            newRow.push(row[th]);
        }
        return newRow;
    });

    let d = {
      title: this.pdfTitle||this.title,
      data: [{
      title: '',
      tableHeaders,
      tableData,
      }]
    };

    this.api.postBlob("json-obj-to-pdf-table", d).subscribe(
      (response) => {
        var blob = new Blob([response], { type: "application/pdf" });
        var file = window.URL.createObjectURL(blob);
        window.open(file);
      },
      (e) => {
        throw Error(e);
      }
    );
  }

  openGroupedPdf() {

    interface JsonObjectToPdfTable {
        title: string;
        tableHeaders: string[];
        tableData: string[][];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Get the currently shown data in the table

    const groupByDirection: string = this.data.sort.direction;
    
    const groupBy: string = groupByDirection != '' ? this.data.sort.active : undefined;

    let tableHeaders = Object.keys(this.data.filteredData[0]);
    // Filter tableHeaders by this.includeColumnSelectorControl
    tableHeaders = tableHeaders.filter((th) =>
      this.includeColumnSelectorControl.value.includes(th)
    );

    const tableData = this.data.filteredData.sort((a, b)=>{
        if(groupByDirection == 'asc'){
            return a[groupBy] > b[groupBy] ? 1 : -1;
        }
        return a[groupBy] < b[groupBy] ? 1 : -1;
    }).map((row) => {
        const newRow = [];
        for (let th of tableHeaders) {
            
            newRow.push(row[th]);
        }
        return newRow;
    });

    const dJson: JsonObjectToPdfTable[] = [];
    let lastGroupByValue: any = undefined;
    for(let row of tableData){
        const groupByIndex = tableHeaders.indexOf(groupBy);

        // See if the current value matches the lastGroupByValue
        if(
            (
                groupBy == undefined 
                && dJson.length > 0 
            )
            || (
                lastGroupByValue === row[groupByIndex]
                && dJson.length > 0 
            )
        ){
            // Add the row to the last group
            dJson[dJson.length - 1].tableData.push(row);
        }else{
            // Create a new group
            dJson.push({
                title: row[groupByIndex],
                tableHeaders: tableHeaders,
                tableData: [row]
            });
            lastGroupByValue = row[groupByIndex];
        }
    }
    
    this.api.postBlob("json-obj-to-pdf-table", {
      title: this.pdfTitle||this.title,
      data: dJson
    }).subscribe(
      (response) => {
        var blob = new Blob([response], { type: "application/pdf" });
        var file = window.URL.createObjectURL(blob);
        window.open(file);
      },
      (e) => {
        throw Error(e);
      }
    );
  }

  openXml() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Get the currently shown data in the table

    const groupByDirection: string = this.data.sort.direction;
    
    const groupBy: string = groupByDirection != '' ? this.data.sort.active : undefined;

    let tableHeaders = Object.keys(this.data.filteredData[0]);
    // Filter tableHeaders by this.includeColumnSelectorControl
    tableHeaders = tableHeaders.filter((th) =>
      this.includeColumnSelectorControl.value.includes(th)
    );

    const tableData = this.data.filteredData.map((row) => {
        const newRow = [];
        for (let th of tableHeaders) {
            
            newRow.push(row[th]);
        }
        return newRow;
    });

    let d = [
      {
        title: 'data',
        tableHeaders: tableHeaders,
        tableData: tableData
      }
    ];

    this.api.postBlob("json-obj-to-xml", d).subscribe(
      (response) => {
        var blob = new Blob([response], { type: "text/xml" });
        var file = window.URL.createObjectURL(blob);
        // Now download the file by creating an anchor with the download attribute, clicking it, and then removing it
        var a = document.createElement("a");
        a.href = file;
        a.download = "data.xml";
        a.click();
        window.URL.revokeObjectURL(file);
      },
      (e) => {
        throw Error(e);
      }
    );
  }

  openGroupedXml() {
    interface JsonObjectToPdfTable {
      title: string;
      tableHeaders: string[];
      tableData: string[][];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Get the currently shown data in the table

    const groupByDirection: string = this.data.sort.direction;
    
    const groupBy: string = groupByDirection != '' ? this.data.sort.active : undefined;

    let tableHeaders = Object.keys(this.data.filteredData[0]);
    // Filter tableHeaders by this.includeColumnSelectorControl
    tableHeaders = tableHeaders.filter((th) =>
      this.includeColumnSelectorControl.value.includes(th)
    );

    const tableData = this.data.filteredData.sort((a, b)=>{
        if(groupByDirection == 'asc'){
            return a[groupBy] > b[groupBy] ? 1 : -1;
        }
        return a[groupBy] < b[groupBy] ? 1 : -1;
    }).map((row) => {
        const newRow = [];
        for (let th of tableHeaders) {
            
            newRow.push(row[th]);
        }
        return newRow;
    });

    const dJson: JsonObjectToPdfTable[] = [];
    let lastGroupByValue: any = undefined;
    for(let rowIndex = 0; rowIndex < tableData.length; rowIndex++){
      const row = tableData[rowIndex];
      const groupByIndex = tableHeaders.indexOf(groupBy);

      // See if the current value matches the lastGroupByValue
      if(
          (
              groupBy == undefined 
              && dJson.length > 0 
          )
          || (
              lastGroupByValue === row[groupByIndex]
              && dJson.length > 0 
          )
      ){
          // Add the row to the last group
          dJson[dJson.length - 1].tableData.push(row);
      }else{
          // Create a new group
          dJson.push({
              title: row[groupByIndex].trim().length > 0? row[groupByIndex].trim(): `NULL-${rowIndex}`,
              tableHeaders: tableHeaders,
              tableData: [row]
          });
          lastGroupByValue = row[groupByIndex];
      }
    }
  
    this.api.postBlob("json-obj-to-xml", dJson).subscribe(
      (response) => {
        var blob = new Blob([response], { type: "text/csv" });
        var file = window.URL.createObjectURL(blob);
        // Now download the file by creating an anchor with the download attribute, clicking it, and then removing it
        var a = document.createElement("a");
        a.href = file;
        a.download = "data.xml";
        a.click();
        window.URL.revokeObjectURL(file);
      },
      (e) => {
        throw Error(e);
      }
    );
  }
}
