import { SageApiService } from "src/app/_services/sageApi/sageApi.service";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,

} from "@angular/core";
import { postBidInterface, QuoteBidInterface } from "src/app/_services/sageApi/interfaces/pullReport/QuoteBid";
import { ScreenSizeService } from "src/app/_services/ScreenSizeService/ScreenSizeService";
import { QuoteEditableService } from "src/app/_services/QuoteEditableService/QuoteEditableService";

@Component({
  selector: "app-BidComponent",
  templateUrl: "./BidComponent.html",
  styleUrls: ["./BidComponent.css", "../QuotingTheme.scss"],
})
export class BidComponent implements OnInit {
  allNonExpiredQuotes: QuoteBidInterface[] = [];

  associatedQuotes: QuoteBidInterface[] = [];

  quotesToRemove = [];
  constructor(
    private sageApi: SageApiService,
    private changeDetectorRef: ChangeDetectorRef,
    public screenSize: ScreenSizeService,
    public qe: QuoteEditableService

  ) {}
  @Input() availableBids: QuoteBidInterface[];
  @Input() associatedBids: QuoteBidInterface[];
  @Input() openBid: boolean = false;
  @Output() sheetClosed = new EventEmitter<boolean>();
  @Input() guid: string;
  ngOnInit() {
    this.qe.updated.subscribe(()=>{
      this.changeDetectorRef.detectChanges();
    })
  }

  resetPage() {
    this.quotesToRemove = [];
   // this.getExistingAssociatedBids();
  }

  removeExisting() {}


  isSaveDisabled() {}

  saveChanges() {
    this.quotesToRemove.forEach(element => {
      let option =  {bid: element, showConfirm: false, showErrors: true}
      console.log(option)
      this.qe.destroyQuoteBid(option);
    });
    this.resetControls();
    this.qe.getAvailableBids();
  }
  resetControls() {
    this.quotesToRemove = [];
    this.associatedQuotes.forEach(element => {
      if(element.checked == true){
        element.checked = false;
      }
    });
    this.changeDetectorRef.detectChanges();
  }
  addToBid(quote, e) {
    if (e == true) {
      if (!this.quotesToRemove.includes(quote)) {
        this.quotesToRemove.push(quote);
      }
    } else {
      if (this.quotesToRemove.includes(quote)) {
        this.quotesToRemove.splice(this.quotesToRemove.indexOf(quote), 1);
      }
    }
    console.log(this.quotesToRemove);
  }
  addOrRemove(quote, e) {
    if (e == true) {
      if (!this.quotesToRemove.includes(quote)) {
        this.quotesToRemove.push(quote);
      }
    } else {
      if (this.quotesToRemove.includes(quote)) {
        this.quotesToRemove.splice(this.quotesToRemove.indexOf(quote), 1);
      }
    }
    console.log(this.quotesToRemove);
  }
  openBottomSheet() {
    this.guid = this.guid;
    this.openBid = true;
  }
  sheetClosedEvent(sheetclosed){
    console.log(sheetclosed)
    this.changeDetectorRef.detectChanges();
    this.openBid=false;
    console.log(this.associatedBids)
  }


}
