<app-bottom-sheet
[open]="openBid"
(sheetClosed)="sheetClosed.emit($event)"
[sheetWidth]="screenSize.getClassesArray().includes('smd')? '100vw' : '75vw'"
[class]="screenSize.getClasses() + ' createqqa'" id="botShet"
>
<h1>Would you like to include any non expired quotes to this bid?</h1>
<div class="searchDiv">
  <mat-form-field
    appearance="outline"
    class=""
  >
    <mat-label>Name</mat-label>
    <input
      (keyup.enter)="searchBids()"
      [(ngModel)]="searchKeyword"
      matInput
      type="text"
      class=""
    />
  </mat-form-field>
    <button
    (click)="searchBids()"
    color="accent"
    mat-flat-button
    class="searchButton"
    >Search</button>
  </div>
<mat-card *ngFor="let quote of quotesSearched;" class="cards">
  <mat-card-content>
    <section>
      <div class="header-row">
        <input type="checkbox" [(ngModel)]="quote.checked" mat-checkbox><h3>{{quote.QuoteName}}</h3>
      </div>
    </section>
  </mat-card-content>
</mat-card>

<div class="action-buttons">
  <button (click)="resetControls()" color="warn" mat-flat-button mat-button>
    Cancel
  </button>
  <button [disabled]="isSaveDisabled()" (click)="saveChanges()" color="primary" mat-flat-button mat-button>
    Save
  </button>
</div>


</app-bottom-sheet>
