<div class="bidview-bread-add-btn">
  <button (click)="openBottomSheet()" mat-fab class="add-button">
    <i class="fas fa-plus"></i>
  </button>
</div>


<div>
  <div>
    <mat-card *ngFor="let quote of associatedBids;" class="cards">
      <mat-card-content>
        <section>
          <div class="header-row">
            <input type="checkbox" [(ngModel)]="quote.checked" mat-checkbox (change)="addOrRemove(quote, $event.target.checked)"><h3>{{quote.QuoteName}}</h3>
          </div>
        </section>
      </mat-card-content>
    </mat-card>
  </div>
</div>
  <div *ngIf="quotesToRemove.length > 0" class="action-buttons">
    <button [disabled]="isSaveDisabled()" (click)="saveChanges()" color="warn" mat-flat-button mat-button>
      Delete
    </button>
    <button [disabled]="isSaveDisabled()" (click)="resetControls()" color="primary" mat-flat-button mat-button>
      Cancel
    </button>
  </div>

<app-bid-create-association-component
[openBid]="openBid"
[guid]="guid"
[availablebids]="availableBids"
(sheetClosed)="sheetClosedEvent($event)"
>
</app-bid-create-association-component>
